import { Link } from 'gatsby';
import React from 'react';
import SavingsIcon from '../../assets/images/savings-icon.svg';

function TalkingCallout() {
  return (
    <section>
      <div className={'talking-callout-container w-full'}>
        <div className="talking-card text-white-100 mx-auto flex flex-col-reverse items-center  justify-center lg:flex-row">
          <div className="w-full">
            <div className="flex flex-col-reverse items-center md:flex-row ">
              <div className="w-full">
                <div className="flex items-center">
                  <div className="flex flex-col md:flex-row w-full">
                    <div className="svg-icon flex justify-center md:justify-start">
                      <img src={SavingsIcon} alt="" width={44} height={84} />
                    </div>
                    <div className="content flex flex-col md:flex-row items-center  md:items-between w-full">
                      <h2 className="mx-auto text-center font-semibold lg:ms-0 lg:text-left">
                        See if you can save on your
                        <br /> Myfembree prescription.
                      </h2>
                      <Link
                        className="button-purple font-mulish inline-block rounded-full font-bold md:mt-3"
                        to="/uterine-fibroids/cost-and-support/"
                      >
                        MYFEMBREE COST SAVINGS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TalkingCallout;
