import React from 'react';

import TalkingBg from '../../assets/images/talking/talking-hero-bg.jpg';
import TalkingBg2x from '../../assets/images/talking/talking-hero-bg@2x.jpg';
import TalkingBg_s from '../../assets/images/talking/talking-hero-bg_s.jpg';
import TalkingBg2x_s from '../../assets/images/talking/talking-hero-bg@2x_s.jpg';

import TalkingWebpBg from '../../assets/images/talking/talking-hero-bg.webp';
import TalkingWebpBg2x from '../../assets/images/talking/talking-hero-bg@2x.webp';
import TalkingWebpBg_s from '../../assets/images/talking/talking-hero-bg_s.webp';
import TalkingWebpBg2x_s from '../../assets/images/talking/talking-hero-bg@2x_s.webp';


function UfAtTheDoctorHero() {
  return (
    <section className="uf-at-the-doctor-hero relative">
      <div className="container-uf-doctor mx-auto">
        <h1 className="text-white-100 flex flex-col items-center justify-between md:flex-row">
          <span className="text-center md:text-left">
          I spoke to my gynecologist about a treatment option to manage my heavy periods from uterine fibroids.
          </span>
        </h1>

        <div className="at-the-doctor-hero-image absolute left-0 top-0 flex flex-col md:flex-row">
          <picture className="">
            <source
              srcSet={`${TalkingWebpBg2x_s}, ${TalkingWebpBg2x_s} 2x`}
              media="(max-width: 672px)"
              type="image/webp"
            />

            <source
              srcSet={`${TalkingBg2x_s}, ${TalkingBg2x_s} 2x`}
              media="(max-width: 672px)"
            />

            <source
              srcSet={TalkingWebpBg2x}
              media="(max-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={TalkingBg2x} media="(max-width: 1152px)" />

            <source
              srcSet={TalkingWebpBg2x}
              media="(min-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={TalkingBg2x} media="(min-width: 1152px)" />

            <img
              className="w-100 img-fluid"
              src={TalkingWebpBg2x}
              alt="Actor portrayal of a smiling woman."
              width={1440}
              height={612}
              fetchpriority="high"
            />
          </picture>
        </div>
      </div>
    </section>
  );
}

export default UfAtTheDoctorHero;
