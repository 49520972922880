import React from 'react';

import YogaTalking from '../../assets/images/talking/talking-yoga@2x.jpg';
import YogaTalkingWebp from '../../assets/images/talking/talking-yoga@2x.webp';
import YogaTalking_s from '../../assets/images/talking/talking-yoga@2x_s.jpg';
import YogaTalkingWebp_s from '../../assets/images/talking/talking-yoga@2x_s.webp';

import useIsEdge from '../../util/use-is-edge';

function TalkingDoctorCalloutUf() {
  const isEdge = useIsEdge();

  function goToQuestions() {
    const sectionOffsetTop =
      document.getElementById('questions-section')!.offsetTop;

    const headerHeight = document
      .querySelector('.site-header')!
      .getBoundingClientRect().height;

    window.scrollTo({
      top: sectionOffsetTop - headerHeight
    });
  }

  return (
    <section>
      <div className={`container mx-auto uf-talking-container ${isEdge ? 'is-edge-container' : ''}`}>
        <div className="uf-talking-doctor-card mx-auto flex flex-col lg:flex-row items-center text-white-100 relative">
          <div className="uf-talking-doctor-card-content">
            <h2 className="font-mulish font-bold text-center lg:text-left mx-auto lg:ms-0">
              Heavy bleeding doesn’t have to be your “normal.”
            </h2>

            <p className="text-center lg:text-left mx-auto lg:ms-0">
              Having to plan around heavy bleeding is not normal. It’s important
              that your gynecologist knows how heavy menstrual bleeding due to
              uterine fibroids impacts you.
            </p>

            <p className="font-semibold text-center lg:text-left mx-auto lg:ms-0">
              Use these questions to help guide the conversation and inform your
              next treatment decision.
            </p>

            <button
              className="white-btn block text-center mx-auto lg:ms-0"
              onClick={goToQuestions}
            >
              <span className="block">START THE CONVERSATION</span>
            </button>
          </div>

          <div className="uf-talking-doctor-card-image relative lg:absolute top-0 right-0 hidden md:block">
            <picture className="">
              <source
                srcSet={YogaTalkingWebp_s}
                media="(max-width: 1152px)"
                type="image/webp"
              />

              <source srcSet={YogaTalking_s} media="(max-width: 1152px)" />

              <source
                srcSet={YogaTalkingWebp}
                media="(min-width: 1152px)"
                type="image/webp"
              />

              <source srcSet={YogaTalking} media="(min-width: 1152px)" />

              <img
                className="w-full img-fluid"
                src={YogaTalking_s}
                alt="Actor portrayal of a woman who takes MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) tablets doing yoga in her home "
                width={585}
                height={602}
              />
            </picture>
          </div>
        </div>
      </div>
      <div className="uf-talking-doctor-card-image relative lg:absolute top-0 right-0 md:hidden">
            <picture className="">
              <source
                srcSet={YogaTalkingWebp_s}
                media="(max-width: 1152px)"
                type="image/webp"
              />

              <source srcSet={YogaTalking_s} media="(max-width: 1152px)" />

              <source
                srcSet={YogaTalkingWebp}
                media="(min-width: 1152px)"
                type="image/webp"
              />

              <source srcSet={YogaTalking} media="(min-width: 1152px)" />

              <img
                className="w-full img-fluid"
                src={YogaTalking_s}
                alt="Actor portrayal of a woman who takes MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) tablets doing yoga in her home "
                width={585}
                height={602}
              />
            </picture>
          </div>
    </section>
  );
}

export default TalkingDoctorCalloutUf;
